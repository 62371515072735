const env = {
  NODE_ENV: "production",
  VERSION: "v0.01",
  NAME: "Multilevel Network System",
  VUE_SESSION_TIMEOUT: 60,
  WS: {
    URL: "https://desafiodaval.com.br/api_indutiva",
    //  URL: "localhost", 
  }
}
export default env;
